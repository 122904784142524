import ApplicationController from '../application_controller';

export default class SortAndFilterController extends ApplicationController {
  static targets = ['buttonLabel', 'option', 'triggerButton', 'sortFilterList'];
  static values = { containerSelector: String };

  toggleSortFilterList() {
    const isListOpen = !this.sortFilterListTarget.classList.contains('hidden');

    if (isListOpen) {
      this.closeSortAndFilterList();
    } else {
      this.sortFilterListTarget.classList.remove('hidden');

      // Remove overflow-x property auto from the container so that the
      // sort and filter dropdown renders on top of design tiles
      if (this.hasContainerSelectorValue) {
        document.querySelector(this.containerSelectorValue).style.overflowX = 'visible';
      }

      this.bindSortAndFilterCloseListEvent();
    }
  }

  closeSortAndFilterList() {
    this.sortFilterListTarget.classList.add('hidden');

    if (this.hasContainerSelectorValue) {
      document.querySelector(this.containerSelectorValue).style.overflowX = 'auto';
    }
  }

  bindSortAndFilterCloseListEvent() {
    const eventHandler = (event) => {
      const isInsideMenu = this.sortFilterListTarget.contains(event.target);
      if (isInsideMenu) return;
      this.closeSortAndFilterList();
      document.removeEventListener('click', eventHandler);
    };

    window.setTimeout(() => {
      document.addEventListener('click', eventHandler);
    }, 0);
  }
}
